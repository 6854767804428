import { template as template_1fb3c16a789440bbbb4c5617122ab38d } from "@ember/template-compiler";
const FKLabel = template_1fb3c16a789440bbbb4c5617122ab38d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
